<template>
  <div id="stageline">
    <div id="stageline-sidebar-container">
      <stageline-sidebar />
    </div>
    <div class="stageline-content-container w-100 d-flex flex-column align-items-center">
      <stageline-graphic
        :stageline-type="this.$router.history.current.params.stagelineType"
      />
      <div v-if="loaded" id="stageline-container" class="w-100" :class="{ 'wide-step': wideStep }">
        <stage-viewer
          @show-contact-modal="showContactModal"
        />
        <div v-if="stagelineLoaded && !filteredStages.length" class="pt-3">
          <h3 class="text-center">
            Check back soon for step by step guidance for forming, managing and maintaining your company
            in {{ jurisdiction.state_province_region }} on your personalized Stageline.
          </h3>
        </div>

        <contact-modal ref="contact-modal" :title="'Add a contact'" :type="'add'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StagelineGraphic from '@/components/StagelineV1/StagelineGraphic'
import StagelineSidebar from '@/components/StagelineV1/StagelineSidebar'
import StageViewer from '@/components/StagelineV1/StageViewer'
import ContactModal from '@/components/ContactModal'
import { setTimeout } from 'timers'

export default {
  name: 'Stageline',
  components: {
    StagelineGraphic,
    StagelineSidebar,
    StageViewer,
    ContactModal,
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
      'stagelineType',
      'filteredStages',
      'jurisdiction',
      'stagelineLoaded',
      'stagelineStatus',
      'currentStep',
      'stagelineJurisdiction',
    ]),
    ...mapGetters('companies', [
      'domesticRegistration',
      'checkoutDomesticJurisdiction',
      'hasExistingRAServiceInJurisdiction',
    ]),
    wideStep() {
      let stepNeedsMoreSpace = false

      if (this.currentStep?.layout_type === 'product') {
        stepNeedsMoreSpace = this.currentStep?.products.length > 3 && !this.hasExistingRAServiceInJurisdiction(this.company.domestic_registration?.jurisdiction)
      }

      return this.currentStep?.layout_type === 'document' || stepNeedsMoreSpace
    },
  },
  async mounted() {
    while (this.company == null) {
      await new Promise(r => setTimeout(r, 200))
    }
    await this.setEinEligibilityStatus(this.company.id)
    await this.setSCorpEligibilityStatus(this.company.id)
    await this.setFincenBoiEligibilityStatus(this.company.id)
    await this.setStagelineJurisdiction()
    this.loaded = true
  },
  async updated() {
    while (this.company == null) {
      await new Promise(r => setTimeout(r, 200))
    }
    this.setStagelineJurisdiction()
  },
  methods: {
    ...mapActions('stageline', [
      'loadStages',
      'setJurisdiction',
    ]),
    ...mapActions('orderItems', [
      'setEinEligibilityStatus',
      'setSCorpEligibilityStatus',
      'setFincenBoiEligibilityStatus',
    ]),
    ...mapActions('companies', ['loadActiveRAServices', 'setCurrentCompany']),
    showContactModal() {
      this.$refs['contact-modal'].show()
    },
    setStagelineJurisdiction() {
      let jur
      if (this.stagelineType === 'expand' && !!this.stagelineJurisdiction) {
        jur = this.stagelineJurisdiction
      } else {
        jur = this.domesticRegistration?.jurisdiction || this.checkoutDomesticJurisdiction
      }
      this.setJurisdiction(jur)
    },
  },
}
</script>

<style scoped lang="scss">
#stageline {
  width: 100vw;
}

#stageline-container {
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.wide-step {
    width: unset !important;
  }
}

@media only screen and (max-width: 1024px) {
  #stageline {
    width: 100% !important;

    .stageline-content-container {
      width: 100% !important;
      max-width: unset;
      margin: 0 !important;

      #stageline-container {
        width: 100% !important;
        padding: 0 2.0em;
        margin: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  #stageline {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-content: center;

    .stageline-content-container {
      padding-top: 1.4em;
    }
  }
}
</style>
